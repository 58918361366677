import React from 'react';
import SVGIcon from '../../assets/svg/SVGIcon';
import { trackSupportAnalyticActivity } from '../../services/dashboardService';
import {
  FirstRow,
  HeadTitle,
  LinkTag,
  RightView,
  SeconView,
  IconView,
  SecondRow,
  TextView,
  HeadCaption,
  CaptionText
} from './Styles';

const HelpAndFeedbackModal = ({ onClose }) => {
  return (
    <div>
      <FirstRow>
        <SVGIcon name='icon-feedback' size={22} fill='var(--gray-icon-color)' strokeWidth={2} />
        <RightView>
          <HeadTitle>Help & Feedback</HeadTitle>
          <IconView onClick={onClose}>
            <SVGIcon name='icon-close' size={20} fill='var(--gray-color)' strokeWidth={2} />
          </IconView>
        </RightView>
      </FirstRow>
      <SeconView>
        <LinkTag
          href={'https://www.salescamp.app/help'}
          target={'_blank'}
          onClick={() => {
            trackSupportAnalyticActivity('help center clicked');
          }}>
          <SecondRow>
            <SVGIcon name='icon-information' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
            <TextView>
              <HeadCaption>Help Center</HeadCaption>
              <CaptionText>Explore all features and best practices.</CaptionText>
            </TextView>
          </SecondRow>
        </LinkTag>
        <LinkTag
          href={'mailto:hello@salescamp.app'}
          target={'_blank'}
          onClick={() => {
            trackSupportAnalyticActivity('talk to us clicked');
          }}>
          <SecondRow>
            <SVGIcon name='icon-message' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
            <TextView>
              <HeadCaption>Talk to us</HeadCaption>
              <CaptionText>Let us know if you having any issues.</CaptionText>
            </TextView>
          </SecondRow>
        </LinkTag>
        <LinkTag
          onClick={() => {
            if (onClose) onClose();
            trackSupportAnalyticActivity('resource clicked');
          }}
          href={'https://www.salescamp.app/free-sales-tools'}
          target={'_blank'}>
          <SecondRow>
            <SVGIcon name='icon-resource' size={20} fill='var(--gray-icon-color)' strokeWidth={2} />
            <TextView>
              <HeadCaption>Resource</HeadCaption>
              <CaptionText>Get free sales tools and resource </CaptionText>
            </TextView>
          </SecondRow>
        </LinkTag>
      </SeconView>
    </div>
  );
};

export default HelpAndFeedbackModal;
